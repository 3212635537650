import { createSlice, createAsyncThunk, current, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid';
import { getRandomLightColor } from "../components/random";
import { getRandomColor } from "../components/random";

export interface ViewsState {
    //groups: any[],
    view: any,
    boreholes: any[],
    allGroupsTheSame: boolean,
    showView: boolean,
    notSaved: boolean
  }
  
  const initialState: ViewsState = {
    //groups: [],
    view: { groups: [] },
    boreholes: [],
    allGroupsTheSame: true,
    showView: false,
    notSaved: false
  }

  const addTracksToGroup2 = (tracks: any[], group: any, groups: any[]) => {
    tracks.forEach((track: any) => {    
      const boreholeGroup = groups.find((g: any) => g.boreholeId == track.boreholeId);
      const backgroundColor = boreholeGroup?.color;      
      if (track.trackType === 1) { //Image
        let myuuid = uuidv4();
        const imageTrackType: any = null;
        const track2: any = {
          id: myuuid,
          boreholeName: track.boreholeName, 
          boreholeId: track.boreholeId,
          trackName: track.trackName, 
          displayName: track.trackName + " Image",
          displayOrder: group.tracks.length + 2, 
          label: track.trackName, 
          imageType: track.imageType,
          trackType: 1,
          trackWidth: 150,
          legend: imageTrackType?.legendName, 
          legendRollover: imageTrackType?.legendRollover,
          color: backgroundColor
          };           
        group.tracks.push(track2);
      } else if (track.trackType === 3 || track.trackType === 4) { // Cumulative, Tadpole
        let myuuid = uuidv4();

        const curves: any = [];
        let curvedisplayOrder = 1;
        track.curves.forEach((curve: any) => {
          const color = getRandomColor();    
          let curveId = uuidv4();          
          curves.push({                                    
            id: curveId,
            displayOrder: curvedisplayOrder,
            fillColor: color + "80",
            fillSize: 5,
            fillStyle: "",
            lineColor: color,
            lineSize: 1,
            lineStyle: "line",
            majorIntervals: 6,
            manualMajorIntervals: false,
            manualMinorIntervals: false,
            manualScaleXMaximum: false,
            manualScaleXMinimum: false,
            minorIntervals: 1,
            pointColor: color,
            pointSize: 5,
            pointStyle: "circle",            
            showFill: track.trackType === 3,
            showLine: true,
            showPoints: track.trackType === 4,
            curveType: track.allowedCurveTypes?.length ? track.allowedCurveTypes[0] : 0,
            trackTypeId: track.trackId,                  
            xScaleMaximum: null,
            xScaleMinimum: null,
            color: backgroundColor,                  
            ...curve });
            curvedisplayOrder++;
        })

        const track2: any = {
          boreholeName: track.boreholeName, 
          boreholeId: track.boreholeId,
          trackName: track.trackName, 
          label: track.trackName, 
          trackId: track.trackId,
          id: myuuid,                                            
          displayOrder: group.tracks.length + 2, 
          new: true, 
          scaleType: 0,
          trackWidth: 150,
          showGridlines: true,
          showValueAxisAnnotation: true, //TODO: get this from somewhere
          curves,
          trackType: track.trackType,
          color: backgroundColor,
          units: track.units,
          allowedCurveTypes: track.allowedCurveTypes, 
        };
        group.tracks.push(track2);
      } else if (track.trackType === 1) { // Graph
        let curveId = uuidv4();
        let myuuid = uuidv4();
        const color = getRandomColor();        
        const track2: any = {
          boreholeName: track.boreholeName, 
          boreholeId: track.boreholeId,
          trackName: track.trackName, 
          label: track.trackName, 
          trackId: track.trackId,
          trackTypeId: track.trackTypeId,
          trackType: track.trackType ?? 0,
          id: myuuid,                                            
          displayOrder: group.tracks.length + 2, 
          new: true, 
          scaleType: 0,
          trackWidth: 150,
          showGridlines: true,
          showValueAxisAnnotation: true, //TODO: get this from somewhere
          color: backgroundColor,
          units: track.units,                
          displayName: track.trackName, 
          curves: [{ 
            displayName: track.trackName, 
            boreholeName: track.boreholeName, 
            boreholeId: track.boreholeId, 
            projectName: track.projectName,
            trackCategoryName: track.trackCategoryName, 
            units: track.units,
            allowedCurveTypes: track.allowedCurveTypes, 
            id: curveId,
            displayOrder: 1,
            fillColor: color + "80",
            fillSize: 5,
            fillStyle: "",
            lineColor: color,
            lineSize: 1,
            lineStyle: "line",
            majorIntervals: 6,
            manualMajorIntervals: false,
            manualMinorIntervals: false,
            manualScaleXMaximum: false,
            manualScaleXMinimum: false,
            minorIntervals: 1,
            pointColor: color,
            pointSize: 5,
            pointStyle: "circle",            
            showFill: true,            
            showLine: true,
            showPoints: false,
            curveType: track.allowedCurveTypes?.length ? track.allowedCurveTypes[0] : 0,
            trackTypeId: track.trackId,                  
            xScaleMaximum: null,
            xScaleMinimum: null,
            color: backgroundColor
          }]
          };            
        group.tracks.push(track2);            
    } else if (track.trackType === 5) { // Lithology              
        let myuuid = uuidv4();              
        const track2: any = {
          boreholeName: track.boreholeName, 
          boreholeId: track.boreholeId,
          trackName: track.trackName, 
          label: track.trackName, 
          trackId: track.trackId,
          trackTypeId: track.trackTypeId,
          trackType: track.trackType ?? 0,
          id: myuuid,                                            
          displayOrder: group.tracks.length + 2, 
          new: true, 
          scaleType: 0,
          trackWidth: 150,
          showGridlines: true,
          showValueAxisAnnotation: true, //TODO: get this from somewhere
          color: backgroundColor,
          units: track.units,                
          displayName: track.trackName              
          };            
        group.tracks.push(track2);
      } else if (track.trackType === 7) { // text
        let myuuid = uuidv4();              
        const track2: any =
         {                                 
          trackTypeId: track.trackTypeId,
          trackType: track.trackType,                                          
          collapsed: track.collapsed,
          boreholeName: track.boreholeName, 
          boreholeId: track.boreholeId,
          trackName: track.trackName, 
          label: track.trackName, 
          trackId: track.trackId,                
          id: myuuid,                                            
          displayOrder: group.tracks.length + 2, 
          new: true, 
          scaleType: 0,
          trackWidth: 150,
          showGridlines: true,
          showValueAxisAnnotation: true, //TODO: get this from somewhere
          color: backgroundColor,
          units: track.units,                
          displayName: track.trackName         
        };
        group.tracks.push(track2);
      } 
    });     
  };

const viewsSlice = createSlice({
    name: 'views',
    initialState,
    reducers: {   
      clearView: (state) => {
        state.showView = false;
        state.view = { groups: [] };
      },       
      addBorehole: (state, action: PayloadAction<any>) => {
        const { boreholeName, boreholeId, projectName, tracks } = action.payload;        
        if (!state.view.groups.find((g: any) => g.boreholeId == boreholeId)) {    
          const color = getRandomLightColor();          
          tracks.forEach((track: any) => {
            track.color = color;
            track.curves?.forEach((curve: any) => {
              curve.color = color;
            })
          });
          state.view.groups.push({ groupName: boreholeName, groupId: uuidv4(), projectName, boreholeId, boreholeName, tracks, color });
        }
        state.boreholes.push({ name: boreholeName, id: boreholeId, project: projectName });
        state.notSaved = true;
        state.showView = true;
      },      
      addTracksToGroup: (state, action: PayloadAction<any>) => {
        const { tracks } = action.payload;        
        state.view.groups.forEach((group: any) => {
          group.tracks = [];  
          tracks.forEach((track: any) => {
            group.tracks.push({...track,
              boreholeName: group.boreholeName, 
              projectName: group.projectName,
              boreholeId: group.boreholeId,
              trackName: track.label,
              trackId: track.id,
              trackType: track.trackType,
              curves: track.curves?.map((curve: any) =>{                 
                return { 
                ...curve, 
                displayName: curve.displayName, 
                boreholeName: group.boreholeName, 
                projectName: group.projectName,
                boreholeId: group.boreholeId,
                trackCategoryName: curve.trackCategoryName
              }})
            })  
          });          
        });

        state.notSaved = true;        
      },      
      addGroup: (state, action: PayloadAction<any>) => {
        const { groupName, tracks } = action.payload;
        if (!state.view.groups.find((g: any) => g.groupName == groupName)) {
          const color = getRandomLightColor();
          state.view.groups.push({ groupName, groupId: uuidv4(), tracks, color });
        }
        state.notSaved = true;
        state.showView = true;
      },
      updateGroupOrder: (state, action: PayloadAction<any>) => {  
        const { sourceIndex, destinationIndex } = action.payload;
        if (sourceIndex !== null && destinationIndex !== null) {                 
          const group = state.view.groups.splice(sourceIndex, 1)[0];          
          state.view.groups.splice(destinationIndex, 0, group);
          state.notSaved = true;
        }
      },
      deleteGroup: (state, action: PayloadAction<any>) => {  
        const { groupId } = action.payload;        
        const groupIndex = state.view.groups.findIndex((g: any) => g.groupId == groupId);          
        if (groupIndex > -1) {
          state.view.groups.splice(groupIndex, 1);
          state.notSaved = true;
        }        
      },
      deleteTrack: (state, action: PayloadAction<any>) => {  
        const { groupId, trackId } = action.payload;        
        const group = state.view.groups.find((g: any) => g.groupId == groupId);          
        if (group) {          
          const trackIndex = group.tracks.findIndex((g: any) => g.trackId == trackId);
          if (trackIndex > -1) {
            group.tracks.splice(trackIndex, 1);          
            state.notSaved = true;
          }
        }        
      },
      deleteTracks: (state, action: PayloadAction<any>) => {  
        const { groupId, trackIds } = action.payload;        
        const group = state.view.groups.find((g: any) => g.groupId == groupId);          
        if (group) {    
          trackIds.forEach((trackId: any) => {
            const trackIndex = group.tracks.findIndex((g: any) => g.trackId == trackId);
            if (trackIndex > -1) {
              group.tracks.splice(trackIndex, 1);          
              state.notSaved = true;
            }
          });
        }        
      },
      deleteAllTracks: (state, action: PayloadAction<any>) => {  
        const { groupId } = action.payload;        
        const group = state.view.groups.find((g: any) => g.groupId == groupId);          
        if (group) {    
            group.tracks = [];
            state.notSaved = true;          
        }        
      },
      addTracks: (state, action: PayloadAction<any>) => {          
        const { groupId, tracks } = action.payload;
        
        const group = state.view.groups.find((g: any) => g.groupId == groupId);          
        if (group) {          
          if (!group.tracks) {
            group.tracks = [];
          }

          addTracksToGroup2(tracks, group, state.view.groups);
               
          state.notSaved = true;          
        }        
      },      
      updateGroupTrackOrder: (state, action: PayloadAction<any>) => {          
        const { sourceGroupId, destinationGroupId, sourceIndex, destinationIndex, applyToAll } = action.payload;
        if (sourceIndex !== null && destinationIndex !== null) {                 
          if (sourceGroupId === destinationGroupId) {            
            if (applyToAll) {
              state.view.groups.forEach((group: any) => {                
                const tracks = group.tracks;
                const track = tracks.splice(sourceIndex, 1)[0];          
                tracks.splice(destinationIndex, 0, track);
              });
            } else {
              const group = state.view.groups.find((g: any) => g.groupId === sourceGroupId);          
              const tracks = group.tracks;
              const track = tracks.splice(sourceIndex, 1)[0];          
              tracks.splice(destinationIndex, 0, track);
            }
          } else {
            // move between groups
            const sourceGroup = state.view.groups.find((g: any) => g.groupId === sourceGroupId);
            const destinationGroup = state.view.groups.find((g: any) => g.groupId === destinationGroupId);
            const sourceTracks = sourceGroup.tracks;
            const destinationTracks = destinationGroup.tracks;
            const track = sourceTracks.splice(sourceIndex, 1)[0];
            destinationTracks.splice(destinationIndex, 0, track);
          }

          state.notSaved = true;
        
        }
      },
      updateGroupName: (state, action: PayloadAction<any>) => {  
        const { groupId, name } = action.payload;                
        const group = state.view.groups.find((g: any) => g.groupId === groupId);          
        if (group) {
          group.groupName = name;
          state.notSaved = true;
        }        
      },
      updateGroupColor: (state, action: PayloadAction<any>) => {  
        const { groupId, color } = action.payload;                
        const group = state.view.groups.find((g: any) => g.groupId === groupId);          
        if (group) {
          group.color = color;
          group.tracks.forEach((track: any) => {
            track.color = color;
            track.curves?.forEach((curve: any) => {
              curve.color = color;
            })
          });
          state.notSaved = true;
        }        
      },
    }    
})

export const { clearView,
               addBorehole, 
               addGroup, 
               updateGroupOrder, 
               deleteGroup, 
               addTracks, 
               deleteTrack, 
               deleteTracks, 
               deleteAllTracks,
               updateGroupTrackOrder, 
               updateGroupName,
               updateGroupColor,
               addTracksToGroup 
              } = viewsSlice.actions

export default viewsSlice.reducer;