import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import { scaleLinear, scaleLog } from 'd3-scale';
import { curveMonotoneY } from 'd3-shape';
import { extent } from 'd3-array';
import { select, stack, scaleOrdinal, area, axisTop, axisLeft, pointer, bisector, line, path, symbol, symbolTriangle, symbolDiamond, symbolSquare } from 'd3';
import FormatNumber from '../format-number';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import lodashDebounce from 'lodash.debounce';
import ReactModal from 'react-modal';
import { ResizableBox, ResizeCallbackData  } from 'react-resizable';
import '../../../node_modules/react-resizable/css/styles.css';
import CurveDialog from './curve-dialog';
import XScaleDialog from './xscale-dialog';
import { v4 as uuidv4 } from 'uuid';
import TrackHeader from './track-header';
import {
  useWindowWidth
} from '@react-hook/window-size'

import './line-chart.scss';
import TrackWrapper from './track-wrapper';

export default function TadpoleChart(props: any) {
  const svgRef: any = useRef();
  const rootSvgRef: any = useRef();  
  const widowWidthRef: any = useRef();

  const { 
      track, 
      id, 
      curves,      
      trackWidth,
      topMargin,
      index,
      startDepth, 
      endDepth, 
      depthUnit,
      showYAxis,       
      updateCurve,      
      showGridlines,
      showValueAxisAnnotation,
      scaleType,      
      setTrackWidth,
      editMode,
      availableHeight,
      headerHeight,
      minHeaderHeight,      
      headerBottomPadding,
      depthMajorIntervals,
      showDepthMinorIntervals,
      depthMinorIntervals,
      showDepthGridLines,
      trackHeaderClick,      
      setMetaDataDepth
    } = props;

  const { datas, scale, isAverage, units, trackTypeId, isSelected, scrollIntoView } = track;
 
  const [curveXRange, setCurveXRange] = useState<any>({});

  const [dialogCurves, setDialogCurves] = useState(curves);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    widowWidthRef.current = windowWidth;
  },[windowWidth]);

  useEffect(() => {
    onSetDialogCurves(curves);
  }, [JSON.stringify(curves)]);

  const onSetDialogCurves = (curves: any) => {
    if (curves && curves.length > 1) {
      setDialogCurves([curves[0]]);
    }
  };

  const onResizeDebounce = (e: SyntheticEvent, data: ResizeCallbackData) => {      
    setTrackWidth(id, data.size.width);
  };

  const onResize = useCallback(lodashDebounce(onResizeDebounce, 40), []);

  const margins = {
    top: topMargin,
    right: 20,
    bottom: 0,
    left: showYAxis ? 40 : 10,
  };

  var w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth,
    y = w.innerHeight|| e.clientHeight|| g.clientHeight;

  const width = trackWidth - margins.left - margins.right;
  const trackHeight = availableHeight - headerBottomPadding - 1;
  const svgHeight = trackHeight - minHeaderHeight;
  const graphHeight = svgHeight - margins.top - margins.bottom;
 
  const getYRange = (data: any) => {
    if (startDepth >= 0 && endDepth && endDepth > startDepth) {
      return [startDepth, endDepth];
    }

    return extent(data, (d: any) => d.depth);
  };
  
  const totalHeight = graphHeight + margins.top + 2 + minHeaderHeight;
  

  const defaultTicks = 6;
  const xTicks = curves[0]?.manualMajorIntervals ? curves[0]?.majorIntervals : defaultTicks;
  const yTicks = depthMajorIntervals;

  var isMajorTick = function (index: any) {    
    if (!showDepthMinorIntervals) {      
      return true;
    }

    return (index % (+depthMinorIntervals + 1)) === 0;
  }

  const addGridLines = (svg: any, xScale: any, yScale: any) => {
    var xAxisGridlines = axisTop(xScale)
    .tickFormat(f => "")
    .tickSize(-graphHeight)
    .ticks(xTicks);
 
    svg.select(".x-axis-grid")
    .call(xAxisGridlines);  

    let ticks = yTicks;
    if (showDepthMinorIntervals) {
      ticks += yTicks * (+depthMinorIntervals);
    }

    var yAxisGridlines = 
    axisLeft(yScale)
    .tickFormat(f => "")
    .tickSize(-width)
    .ticks(ticks);
 
    svg.select(".y-axis-grid")
    .selectAll("*")
    .remove();

    if (showDepthGridLines) {
      const yAxisGrid = svg.select(".y-axis-grid")
      .call(yAxisGridlines);

      yAxisGrid.selectAll("g")
        .filter(function (d : any, i : any) {
          return !isMajorTick(i);
        })
        .classed("grid-minor-tick", true);
    } 
  };

  const onSetDialogCurveProperties = (curveId: any, curveProperties: any) => {
    let curveIndex = dialogCurves.findIndex((curve: any) => curve.id === curveId);
    let curve = dialogCurves[curveIndex];
    let c = dialogCurves.slice();
    c.splice(curveIndex, 1);
    const curvesCopy = [...c, {...curve, ...curveProperties}];
    curvesCopy.sort((a: any, b:any) => a.displayOrder - b.displayOrder);
    onSetDialogCurves(curvesCopy);
  };

const onSetCurveXRange = (curveId: any, minimum: number, maximum: number) => {
  minimum = Math.round(minimum * 100) / 100;
  maximum = Math.round(maximum * 100) / 100;
  setCurveXRange((prevState: any) => ({
    ...prevState,
    ...{ [curveId]: {minimum, maximum} }    
  }));  

  let curveIndex = curves.findIndex((curve: any) => curve.id === curveId);
  let curve :any = curves[curveIndex];
  let newCurve =  { ...curve, xScaleMinimumExtent: minimum, xScaleMaximumExtent: maximum };
 
  if (!curve.manualScaleXMinimum || !curve.manualScaleXMaximum) {    
    if (!curve.manualScaleXMinimum) {
      newCurve.xScaleMinimum = minimum;
    }

    if (!curve.manualScaleXMaximum) {
      newCurve.xScaleMaximum = maximum;
    }    
  }

  if (!curve.manualMajorIntervals) {    
    newCurve.majorIntervals = defaultTicks;
  } 

  updateCurve(id, newCurve);
}
  
const xScaleRange = (cumulativeData: any, curve: any) => {
  if (curve.manualScaleXMinimum && curve.manualScaleXMaximum) {    
    onSetCurveXRange(curve.id, curve.xScaleMinimum, curve.xScaleMaximum);
    return [curve.xScaleMinimum, curve.xScaleMaximum];
  }

  const rangeExtent : any[] = extent(cumulativeData, (d : any) => d[curve.id]);  
  let newCurve =  { ...curve, xScaleMinimumExtent: rangeExtent[0], xScaleMaximumExtent: rangeExtent[1] };
  if (curve.manualScaleXMinimum) {
    rangeExtent[0] = curve.xScaleMinimum;
  } 
  
  if (curve.manualScaleXMaximum) {
    rangeExtent[1] = curve.xScaleMaximum;
  } 
    
  if (!curve.manualScaleXMinimum || !curve.manualScaleXMaximum) {    
    if (!curve.manualScaleXMinimum) {
      newCurve.xScaleMinimum = rangeExtent[0];
    }

    if (!curve.manualScaleXMaximum) {
      newCurve.xScaleMaximum = rangeExtent[1];
    }    
  }

  onSetDialogCurveProperties(curve.id, newCurve);

  onSetCurveXRange(curve.id, rangeExtent[0], rangeExtent[1]);
  
  return rangeExtent;
};

const renderChart = (tadpoleData: any, curves: any[]) => {
  const svg = select(svgRef.current!);
    
  
  const positionCurve = curves[0];
  const angleCurve = curves[1];
  
  const yScale = scaleLinear()
  .domain(getYRange(tadpoleData))
  .range([0, graphHeight]);
  
 const xScale = scaleType == 0 ? scaleLinear()
  .domain(xScaleRange(tadpoleData, positionCurve))
  .range([0, width])
  .nice(xTicks)
  :
  scaleLog()
  .domain(xScaleRange(tadpoleData, positionCurve))
  .range([0, width])
  .nice();
 
  let dotsData = tadpoleData.filter((d: any) => xScale(d[positionCurve.id]) <= width );
  
  if (!positionCurve.showPoints) {
    dotsData = [];
  }

    var dots : any = svg
    .selectAll(".dot" + positionCurve.id)
    .data(dotsData);
  
    dots.exit().remove();
    
    dots.enter()        
        .append("circle")
        .attr("class", "dot" + positionCurve.id)
        .attr('clip-path', 'url(#clip)')
        .merge(dots)
          .attr("r", positionCurve.pointSize / 2)
          .attr("cx", function (d: any) { return xScale(d[positionCurve.id]); } )
          .attr("cy", function (d: any) { return yScale(d.depth); } )        
          .attr("fill", positionCurve.pointColor)
          .attr("stroke-width", 1)
          .attr("stroke", positionCurve.pointColor);


  let tailData = tadpoleData.filter((d: any) => { 
    const positionValue = d[positionCurve.id];
    return xScale(positionValue) <= width && positionValue !== 0 && positionValue !== Math.abs(90) });  
  if (!positionCurve.showLine) {
    tailData = [];
  }

    var tails : any = svg
    .selectAll(".tail" + positionCurve.id)
    .data(tailData);

    tails.exit().remove();

    function drawTail(xOffset: number, yOffset: number, angle: number){  
      const circleSize = positionCurve.pointSize / 2;
      const lineLength = circleSize + 5 + (positionCurve.lineSize * 2);
      
      const radians = (angle - 90) * (Math.PI / 180);
      const xStart = positionCurve.showPoints ? circleSize * Math.cos(radians) : 0;
      const yStart = positionCurve.showPoints ? circleSize * Math.sin(radians) : 0;
      //path.moveTo(xStart, yStart);
      const xEnd = lineLength * Math.cos(radians);
      const yEnd = lineLength * Math.sin(radians);
      //path.lineTo(xEnd, yEnd);

      return { x1: xStart + xOffset,
               x2: xEnd + xOffset,
               y1: yStart + yOffset,
               y2: yEnd + yOffset };
    }

      
    tails.enter()        
      .append("line")          
          .attr("stroke-width", 1)      
          .attr("class", "tail" + positionCurve.id)      
          .attr('clip-path', 'url(#clip)')    
          .merge(tails)
          //.attr("d", (d: any) => drawTail(xScale(d[positionCurve.id]),yScale(d.depth), d[angleCurve.id]))
          .attr("x1", (d: any) => drawTail(xScale(d[positionCurve.id]),yScale(d.depth), d[angleCurve.id]).x1)
          .attr("x2", (d: any) => drawTail(xScale(d[positionCurve.id]),yScale(d.depth), d[angleCurve.id]).x2)
          .attr("y1", (d: any) => drawTail(xScale(d[positionCurve.id]),yScale(d.depth), d[angleCurve.id]).y1)
          .attr("y2", (d: any) => drawTail(xScale(d[positionCurve.id]),yScale(d.depth), d[angleCurve.id]).y2)
          .attr("stroke", positionCurve.lineColor)
          //.attr("stroke-width", angleCurve.lineSize)
          //.attr("transform", function (d: any) { return `translate(${xScale(d[positionCurve.id])},${yScale(d.depth)})` });
          //.attr('clip-path', 'url(#clip)');

    const xAxis: any = axisTop(xScale).tickFormat(f => FormatNumber(f)).ticks(xTicks);

    if (showValueAxisAnnotation) {
      svg.select(".x-axis")
      .call(xAxis);
     
     svg.select(".x-axis").selectAll("text")
      .attr("y", 0)
      .attr("x", 9)
      .attr("dy", ".35em")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "start");
    } else {
      svg.select(".x-axis")
      .selectAll("*").remove();
    }

    addGridLines(svg, xScale, yScale);
   
 var bisect = bisector(function(d: any) { return d.depth; }).left;

 function handleMouseMove(event: any) {   
    const currentYPosition = pointer(event)[1];
    const yValue = yScale.invert(currentYPosition);
    
    const index = bisect(tadpoleData, yValue);
    
    const value = tadpoleData[index]?.[curves[0].id];
    const depth = tadpoleData[index]?.depth;

    if (value == null || !depth) {      
      return;
    }
    
    // Get the index of the xValue relative to the dataSet
    const focus = svg
    .select('.focus-circle');
        
    focus
    .attr("cx", xScale(value))
    .attr("cy", yScale(depth))
    .raise(); 

    let valueArray: any[] = [];
    curves.forEach((curve: any) => {
      const val = tadpoleData[index]?.[curve.id];
      if (!isNaN(val)) {
        valueArray.push({ displayName: curve.displayName, value: (Math.round(val * 10) / 10), units: "&deg;", isAverage: curve.isAverage, scale: curve.scale});
      }
    });

    setMetaDataDepth({ depth, values: valueArray});

  }

    function handleMouseOver(event: any) {
      const focus = svg
      .select('.focus-circle');
      focus.style("opacity", 0.5);
      handleMouseMove(event);
    }

    function handleMouseOut() {
      const focus = svg
      .select('.focus-circle');    
      focus.style("opacity", 0);
      setMetaDataDepth(null);
    }

    svg.append("rect")
    .attr("class", "overlay" + uuidv4())
    .attr("width", width)
    .attr("height", graphHeight)
    .style("opacity", 0)
    .on("mouseover", handleMouseOver)
    .on("mouseout", handleMouseOut)
    .on("mousemove", handleMouseMove);
}

  useEffect(() => {
    if (!datas || datas.length === 0 || !curves) {
      return;
    }
    const reversedCurves = [...curves];    
    reversedCurves.sort((a: any, b:any) => a.displayOrder - b.displayOrder);
    let count = 1;
    const totalCurves = reversedCurves.length;
    let tadpoleData: any[] = [];
    reversedCurves.forEach((curve: any, index: number) => {
      var trackTrackData = datas?.find((d: any) => curve.trackTypeId == d.trackTypeId);
      trackTrackData?.data?.forEach((data: any, dataIndex: number) => {
        const existingDataIndex = tadpoleData.findIndex((d: any) => d.depth === data.depth);        
        if (existingDataIndex > -1) {
          const existingData = tadpoleData[existingDataIndex];
          tadpoleData[existingDataIndex] = {...existingData, depth: data.depth, [curve.id]: data.value};
        } else{
          tadpoleData[dataIndex] = {depth: data.depth, [curve.id]: data.value};
        }
      });
    });

    if (tadpoleData) {
      debugger;
      renderChart(tadpoleData, reversedCurves);  
      count++;
    }
    
  }, [JSON.stringify(datas), 
      JSON.stringify(curves),
      availableHeight, 
      startDepth, 
      endDepth,       
      scaleType,
      trackWidth,
      editMode,
      showDepthMinorIntervals,
      depthMajorIntervals,
      showDepthGridLines,
      minHeaderHeight,
      depthMinorIntervals,
      showValueAxisAnnotation]);

  if (!datas) {
    return null;
  }

const headerPadding = () => {
    const totalHeight = headerHeight - headerBottomPadding; //* curves.length;
    const padding = minHeaderHeight - totalHeight;
    if (padding > 0) {
      return <div style={{minHeight: padding}}></div>
    }
    
    return null;
  };

const headerCurves = [curves[0]];

  return (
    <TrackWrapper trackWidth={trackWidth}           
      onResize={onResize}
      chartHeight={trackHeight}
      scrollIntoView={scrollIntoView}
      editMode={isSelected}
      trackClick={trackHeaderClick}>
    {/* <div className="is-size-6 has-text-centered track-label" ref={ref} title={label}>{label}</div> */}

    <div className="has-text-centered track-label">
        {headerPadding()}
        {(headerCurves || []).map((curve: any, index: number) => (
            <TrackHeader 
              key={curve.id}
              curve={curve} 
              trackWidth={trackWidth} 
              curveXRange={curveXRange} 
              isSelected={isSelected && index === 0}
              last={index === headerCurves.length - 1}
            />       
        ))}        
      </div>     
      <svg width={trackWidth}
        height={svgHeight}
        ref={rootSvgRef}>
         <defs>
          <clipPath id="clip">
            <rect className="clip-rect" x={0} y={0} width={1000} height={graphHeight} />
          </clipPath>
        </defs>
        <g ref={svgRef} transform={`translate(${margins.left}, ${margins.top})`}>
          <g className="unit">
              <text className="unit-text"></text>
          </g>
          <g className="x-axis-grid gridLines vertical-grid-lines" strokeOpacity={showGridlines ? 1 : 0 }/>
          <g className="y-axis-grid gridLines horizontal-grid-lines" strokeOpacity={showDepthGridLines ? 1 : 0 }/>
          <g className="x-axis" />          
          <g className="y-axis" />  
          <circle className="focus-circle" r={5} fill="none" stroke="black" opacity={0}/>                            
        </g>
      </svg>
    </TrackWrapper>
  );  
}
